<template lang="pug">
  FormModal.modal-delete-folder(title="Delete Folder", :endpoint="`folders/${this.folder_id}/delete`", :data="data", v-bind="$attrs", @success="onSuccess", button-text="DELETE")
    p Are you sure you want to delete folder #[strong {{ folder.name }}]?

    div.mt-6(v-if="folder.collections_count")
      p Choose what to do with existing collections in this folder:

      InputGroup.mb-0.mt-10(label="Choose Action", name="collection_action")
        Select(v-model="data.collection_action", :options="actionOptions")
</template>

<script>
import { get } from "lodash-es"

export default {
  props: {
    folder_id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    actionOptions: [
      { id: "move-to-main", name: "Move collections in this folder to the main folder" },
      { id: "delete", name: "Delete all collections in this folder" }
    ],
    folder: {},
    data: {
      collection_action: "move-to-main"
    }
  }),
  async created() {
    let response = await this.$api.get(`folders/${this.folder_id}`)
    this.folder = get(response, "data.item", {})
  },
  methods: {
    onSuccess(result) {
      this.$notification.success(`Folder **${this.folder.name}** successfully deleted.`)
      this.$emit("close", result)
    }
  }
}
</script>

<style>
.modal-delete-folder {
  .button.primary {
    @apply border-red bg-red;

    &:hover {
      @apply border-red-600 bg-red-600;
    }
  }
}
</style>
