<template lang="pug">
  FormModal.modal-edit-folder(title="Edit Folder", :endpoint="`folders/${this.folder_id}`", method="PUT", :data="data", v-bind="$attrs", @success="onSuccess")
    InputGroup(label="Name", name="name")
      TextInput(v-model="data.name", prepend-icon="folder", icon-prefix="fas", auto-focus)

    InputGroup(label="Parent Folder — optional", name="parent_id")
      Select(v-model="data.parent_id", :endpoint="`folders/${folder_id}/update-folder-options`", option-label="label", prepend-option="No Folder", prepend-icon="folder", prepend-icon-prefix="fas")
</template>

<script>
import { get, merge, pick } from "lodash-es"
import { mapGetters } from "vuex"

export default {
  props: {
    folder_id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    data: {
      name: "",
      parent_id: null
    }
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  async created() {
    let response = await this.$api.get(`folders/${this.folder_id}`)
    let folder = get(response, "data.item", {})
    this.data = merge(this.data, pick(folder, ["parent_id", "name"]))
  },
  methods: {
    onTypeChange() {
      this.data.parent_id = null
    },
    onSuccess(result) {
      this.$notification.success(`Folder **${this.data.name}** successfully updated.`)
      this.$emit("close", result)
    }
  }
}
</script>
