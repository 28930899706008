<template lang="pug">
AppLayout
  Band(contained, centered)
    Row(lg="row")
      Column(class="lg:w-3/4")
        Breadcrumbs(:items="breadcrumbs", v-if="breadcrumbs")
        ContentHeader(:page-title="$markdown(collection.name)", :code="collection.code")
        Content(v-if="collection.contents", :items="collection.contents", show-code, show-controls)
        div.mt-6
          span(v-if="collection.contents && collection.contents.length > 1") {{ collection.contents.length }} items.
          span(v-else-if="collection.contents && collection.contents.length === 1") 1 item.
          span(v-else) No items.

      Column.sidebar(class="lg:w-1/4")
        Button.btn--customise-collection(v-if="user.hasPermission('collections.shared_manage') || user.hasPermission('collections.manage')", type="primary", prepend-icon="palette", @click="viewLandingPage(true)") Design

        SidebarWidget(title="Share", v-if="user.hasPermission('sharing.landing_pages') || (user.hasPermission('advanced.shared_collection_invites') && user.partner.has_premium_share)")
          Button(type="link", prepend-icon="eye", @click="viewLandingPage()") View Live
          Button(type="link", prepend-icon="link", v-clipboard="landingPageURL", v-clipboard:success="onCopy") Copy Link
          Button(type="link", prepend-icon="envelope", @click="emailLandingPage()") Email
          Button(type="link", prepend-icon="share", v-clipboard="collection.invite_url", v-clipboard:success="onCopy", v-if="user.partner.has_premium_share && user.hasPermission('advanced.shared_collection_invites') || user.internal") Invite URL
          Button(v-if="user.partner.has_premium_share && user.hasPermission('advanced.shared_collection_invites') || user.internal", type="link", prepend-icon="qrcode", @click="openInviteQrCodeModal") Invite QR code

        SidebarWidget(title="Manage Content", v-if="(collection.type === 'partner' && user.hasPermission('collections.shared_manage')) || (collection.type === 'personal' && user.hasPermission('collections.manage'))")
          Button(v-if="user.hasPermission('blocks.heading')", type="link", @click="addHeadingBlock()")
            template(v-slot:prepend): span(class="bg-white border border-l-2 border-l-blocks-heading flex items-center justify-center h-8 w-12 rounded text-blocks-heading"): Icon(icon="block-heading")
            span Add Heading
          Button(v-if="user.hasPermission('blocks.text')", type="link", @click="addTextBlock()")
            template(v-slot:prepend): span(class="bg-white border border-l-2 border-l-blocks-text flex items-center justify-center h-8 w-12 rounded text-blocks-text"): Icon(icon="block-text")
            span Add Text
          Button(v-if="user.hasPermission('blocks.cta')", type="link", @click="addCTABlock()")
            template(v-slot:prepend): span(class="bg-white border border-l-2 border-l-blocks-cta flex items-center justify-center h-8 w-12 rounded text-blocks-cta"): Icon(icon="block-cta")
            span Add Call to Action
          Button(v-if="user.hasPermission('blocks.note')", type="link", @click="addNoteBlock()")
            template(v-slot:prepend): span(class="bg-white border border-l-2 border-l-blocks-note flex items-center justify-center h-8 w-12 rounded text-blocks-note"): Icon(icon="block-note")
            span Add Note
          Button(v-if="user.hasPermission('blocks.image')", type="link", @click="addImageBlock()")
            template(v-slot:prepend): span(class="bg-white border border-l-2 border-l-blocks-image flex items-center justify-center h-8 w-12 rounded text-blocks-image"): Icon(icon="block-image")
            span Add Image
          Button(v-if="user.hasPermission('blocks.itinerary') && user.partner.has_premium_itineraries", type="link", @click="addItineraryBlock()")
            template(v-slot:prepend): span(class="bg-white border border-l-2 border-l-blocks-itinerary flex items-center justify-center h-8 w-12 rounded text-blocks-itinerary"): Icon(icon="block-itinerary")
            span Add Itinerary
          Button(v-if="user.partner.has_premium_forms && user.hasPermission('advanced.forms') || user.internal", type="link", @click="addLeadGenerationFormBlock()")
            template(v-slot:prepend): span(class="bg-white border border-l-2 border-l-blocks-form flex items-center justify-center h-8 w-12 rounded text-blocks-form"): Icon(icon="block-form")
            span Add Lead Form
            span.sidebar-widget__badge PREMIUM
          //- Button(type="link")
            template(v-slot:prepend): span(class="bg-white border border-l-2 border-l-blocks-content flex items-center justify-center h-8 w-12 rounded text-blocks-content"): Icon(icon="block-content")
            span Add Content
          Button(type="link", @click="reorderCollection()")
            template(v-slot:prepend): span(class="flex items-center justify-center h-8 w-8"): Icon(icon="arrows-v")
            span Reorder

        SidebarWidget(title="Manage Collection", v-if="user.hasPermission('collections.duplicate') || (collection.type === 'partner' && user.hasPermission('collections.shared_manage')) || (collection.type === 'personal' && user.hasPermission('collections.manage'))")
          Button(type="link", prepend-icon="pencil", @click="editCollection()", v-if="(collection.type === 'partner' && user.hasPermission('collections.shared_manage')) || (collection.type === 'personal' && user.hasPermission('collections.manage'))") Edit (Name, Publish, Expiration)
          Button(type="link", prepend-icon="clone", @click="duplicateCollection()", v-if="user.hasPermission('collections.duplicate')") Duplicate
          Button(type="link", prepend-icon="trash", @click="deleteCollection()", v-if="(collection.type === 'partner' && user.hasPermission('collections.shared_manage')) || (collection.type === 'personal' && user.hasPermission('collections.manage'))") Delete
</template>

<script>
import { Breadcrumbs } from "@syntax51/app-kit"
import { each, get } from "lodash-es"
import { mapGetters } from "vuex"
import Content from "@/components/Content/Content"
import ContentHeader from "@/components/Content/ContentHeader"
import SidebarWidget from "@/components/SidebarWidget"

const AddCTABlockModal = () => import("@/modals/Blocks/CTA/AddCTABlockModal")
const AddHeadingBlockModal = () => import("@/modals/Blocks/Heading/AddHeadingBlockModal")
const AddImageBlockModal = () => import("@/modals/Blocks/Image/AddImageBlockModal")
const AddItineraryBlockModal = () => import("@/modals/Blocks/Itinerary/AddItineraryBlockModal")
const AddNoteBlockModal = () => import("@/modals/Blocks/Note/AddNoteBlockModal")
const AddTextBlockModal = () => import("@/modals/Blocks/Text/AddTextBlockModal")
const DuplicateCollectionModal = () => import("@/modals/Collections/DuplicateCollectionModal")
const EditCollectionModal = () => import("@/modals/Collections/EditCollectionModal")
const ReorderCollectionModal = () => import("@/modals/Collections/ReorderCollectionModal")
const CollectionInviteQrCodeModal = () => import("@/modals/Collections/CollectionInviteQrCodeModal")

export default {
  components: {
    Breadcrumbs,
    Content,
    ContentHeader,
    SidebarWidget
  },
  metaInfo() {
    return { title: this.collection.name, bodyAttrs: { class: "page-collections-view-collection" } }
  },
  computed: {
    ...mapGetters({
      collection: "collection/collection",
      user: "auth/user"
    }),
    breadcrumbs() {
      let crumbs = get(this.collection, "breadcrumbs", [])
      let breadcrumbs = [{ label: "Collections", to: { name: "collections" } }]

      each(crumbs, breadcrumb => {
        let crumb = { label: breadcrumb.name }
        if (!breadcrumb.last) crumb.to = { name: "collection.folder", params: { id: breadcrumb.id } }
        breadcrumbs.push(crumb)
      })

      return breadcrumbs
    },
    landingPageURL() {
      let landingPageURL = this.collection.landing_page_url
      let { display_contact_info_on_links } = this.user

      if (display_contact_info_on_links === "personal") {
        landingPageURL += "?uid=" + this.user.get("uid")
      } else if (display_contact_info_on_links === "company") {
        landingPageURL += "?pid=" + this.user.get("partner.pid")
      }

      return landingPageURL
    }
  },
  watch: {
    $route: "fetch"
  },
  methods: {
    fetch() {
      this.$store.dispatch("collection/find", this.$route.params.id)
    },
    viewLandingPage(designMode = false) {
      const url = new URL(this.landingPageURL)

      if (designMode) {
        const exploreUrl = new URL(process.env.VUE_APP_EXPLORE_URL)
        const urlParams = new URLSearchParams(url.search)
        urlParams.set("oe", true)
        url.search = urlParams.toString()
        url.host = exploreUrl.host
      }

      window.open(url, "_blank")
    },
    emailLandingPage() {
      let emailTemplate = this.user.get("landing_page_email_template")

      emailTemplate = emailTemplate.replace("{{collectionLink}}", this.landingPageURL)
      emailTemplate = emailTemplate.replace("{{userName}}", this.user.first_name)

      window.location.href = `mailto:?subject=${encodeURIComponent(this.collection.name)}&body=${encodeURIComponent(
        emailTemplate
      )}`
    },
    async editCollection() {
      let response = await this.$modal.show(EditCollectionModal, { collection_id: this.collection.id })
      if (response) this.fetch()
    },
    async duplicateCollection() {
      let response = await this.$modal.show(DuplicateCollectionModal, { collection: this.collection })
      if (response) this.$router.push({ name: "collection", params: { id: response.item.id } })
    },
    openInviteQrCodeModal() {
      this.$modal.show(CollectionInviteQrCodeModal, { hashid: this.collection.hashid })
    },
    reorderCollection() {
      this.$modal.show(ReorderCollectionModal, { collection: this.collection })
    },
    async deleteCollection() {
      if (
        await this.$modal.confirm({
          type: "danger",
          message: `Are you sure you want to delete **${this.collection.name}**?`,
          buttonText: "Delete"
        })
      ) {
        let response = await this.$api.delete(`collections/${this.collection.id}`)

        if (response) {
          this.$notification.success(`Collection **${this.collection.name}** successfully deleted.`)
          this.$router.push({ name: "collections" })
        }
      }
    },
    onCopy() {
      this.$notification.success("Copied!")
    },
    async addCTABlock() {
      let response = await this.$modal.show(AddCTABlockModal, { collection_id: this.collection.id })
      if (response) this.addBlock(response.data)
    },
    async addHeadingBlock() {
      let response = await this.$modal.show(AddHeadingBlockModal, { collection_id: this.collection.id })
      if (response) this.addBlock(response.data)
    },
    async addImageBlock() {
      let response = await this.$modal.show(AddImageBlockModal, { collection_id: this.collection.id })
      if (response) this.addBlock(response.data)
    },
    async addItineraryBlock() {
      let response = await this.$modal.show(AddItineraryBlockModal, { collection_id: this.collection.id })
      if (response) this.addBlock(response.data)
    },
    async addLeadGenerationFormBlock() {
      let response = await this.$api.post("blocks", {
        block_type: "form",
        form: "lead-gen",
        collection_id: this.collection.id
      })
      if (response) {
        this.$notification.success(`Lead generation form successfully added.`)
        this.addBlock(response.data.data)
      }
    },
    async addNoteBlock() {
      let response = await this.$modal.show(AddNoteBlockModal, { collection_id: this.collection.id })
      if (response) this.addBlock(response.data)
    },
    async addTextBlock() {
      let response = await this.$modal.show(AddTextBlockModal, { collection_id: this.collection.id })
      if (response) this.addBlock(response.data)
    },
    addBlock(block) {
      this.$store.dispatch("collection/addBlock", block)
    }
  }
}
</script>

<style>
.page-collections-view-collection {
  .breadcrumbs {
    @apply mb-2;
  }

  .page-title {
    @apply mt-2;
  }

  .sidebar-widget .button {
    @apply mt-3 block;

    &-prepend {
      @apply justify-center;
    }
  }

  .btn--customise-collection {
    @apply mb-12 border-secondary-default bg-secondary-default;

    &:hover {
      @apply border-secondary-hover bg-secondary-hover;
    }
  }
}
</style>
