<template lang="pug">
  FormModal.modal-new-folder(title="New Folder", endpoint="folders", :data="data", v-bind="$attrs", @success="onSuccess")

    InputGroup(label="Privacy", name="type", v-if="user.hasPermission('collections.shared_manage') && user.hasPermission('collections.manage')")
      Radios(v-model="data.type", inline)
        Radio(value="partner", @change="onTypeChange()")
          Icon(icon="users")
          div
            div: strong Shared
            div.option-label Shared among all members of your team.
        Radio(value="personal", @change="onTypeChange()")
          Icon(icon="user")
          div
            div: strong Personal Folder
            div.option-label Private and visible only to you.

    InputGroup(label="Name", name="name")
      TextInput(v-model="data.name", prepend-icon="folder", icon-prefix="fas", auto-focus)

    InputGroup(label="Parent Folder — optional", name="parent_id", v-if="data.type")
      Select(v-model="data.parent_id", :endpoint="`options/${data.type}/folders`", option-label="label", prepend-option="No Folder", prepend-icon="folder", prepend-icon-prefix="fas")
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: {
    type: {
      type: String,
      default: null
    },
    folder_id: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    data: {
      parent_id: null,
      type: null,
      name: ""
    }
  }),
  computed: {
    ...mapGetters({
      user: "auth/user"
    })
  },
  created() {
    if (this.type === "partner" && this.user.hasPermission("collections.shared_manage")) {
      this.data.type = "partner"
    } else if (this.type === "personal" && this.user.hasPermission("collections.manage")) {
      this.data.type = "personal"
    } else if (this.user.hasPermission("collections.shared_manage") && this.user.hasPermission("collections.manage")) {
      this.data.type = "partner"
    } else if (this.user.hasPermission("collections.shared_manage")) {
      this.data.type = "partner"
    } else if (this.user.hasPermission("collections.manage")) {
      this.data.type = "personal"
    }

    this.data.parent_id = this.folder_id
  },
  methods: {
    onTypeChange() {
      this.data.parent_id = null
    },
    onSuccess(result) {
      this.$notification.success(`Folder **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
